<template>
  <div>
    <div class="compony-container" v-if="companyList.length > 0">
      <div class="rem36 lh25 c-ffffff" style="font-weight: 600">企业信息查看</div>
      <div class="compony-body" v-for="(company, index) in companyList" :key="index">
        <div class="flex align-center">
          <div class="body-left">
            <div class="flex align-center">
              <span class="blue-point"></span><span class="c-303133 rem26 lh18">企业名称</span>
            </div>
            <!-- <div class="rem30 lh21 mt8">{{ company.enterpriseName }}</div> -->
            <van-field :disabled="!company.edit" type="text" v-model="company.enterpriseName" />
          </div>
          <div class="flex align-center c-4D7BFE" @click="company.show = !company.show">
            <span class="rem22"> {{ company.show ? '收起' : '展开' }} </span>
            <van-icon :name="company.show ? 'arrow-up' : 'arrow-down'" />
          </div>
        </div>
        <!-- 展开内容 -->
        <div v-show="company.show" style="position: relative">
          <div>
            <van-divider />
            <div class="c-303133 rem26 lh18">社会统一信用代码</div>
            <!-- <div class="rem30 lh21 mt8">{{ company.code }}</div> -->
            <van-field :disabled="!company.edit" type="text" v-model="company.enterpriseCertNo" />
            <van-divider />
            <div class="c-303133 rem26 lh18">企业地址</div>
            <van-field :disabled="!company.edit" type="text" v-model="company.enterpriseAddress" />
            <!-- <div class="rem30 lh21 mt8">{{ company.address }}</div> -->
            <van-divider />
            <div class="c-303133 rem26 lh18">法人代表名称</div>
            <van-field :disabled="!company.edit" type="text" v-model="company.legalPersonName" />
            <!-- <div class="rem30 lh21 mt8">{{ company.legalName }}</div> -->
            <van-divider />
            <div class="c-303133 rem26 lh18">法人代表证件号</div>
            <van-field :disabled="!company.edit" type="text" v-model="company.idNo" />
            <!-- <div class="rem30 lh21 mt8">{{ company.legalIdNumber }}</div> -->
          </div>
          <div class="edit-btn" v-if="!company.edit" @click="company.edit = true">修改</div>
          <van-button
            v-if="company.edit"
            round
            type="info"
            size="large"
            @click="handleEditCompanyInfo(company)"
          >
            保存
          </van-button>
        </div>
        <!-- 修改按钮 -->
      </div>
    </div>

    <div v-if="emptyPage" class="empty-page">
      <van-image class="bg" width="237" height="167" :src="noCompanyBg" />
      <div class="text">暂无企业信息，请先添加企业</div>
      <div class="add-company" @click="addCompany">
        <img :src="addPng" alt="" />
        <span>添加企业</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon, Divider, Image, Button, Field } from 'vant'
import { getCompanyListApi, updateCompanyInfoApi } from '@/apis/userCenter/index'
import { Decrypt } from '@/utils/crypto'

Vue.use(Icon).use(Divider).use(Image).use(Button).use(Field)
export default {
  data() {
    return {
      show: true,
      companyList: [],
      noCompanyBg: require('@/assets/images/user/no-company-bg.png'),
      addPng: require('@/assets/images/credit/add.png'),
    }
  },
  created() {
    this.getList()
  },
  computed: {
    emptyPage() {
      return this.companyList.length === 0
    },
  },
  methods: {
    async getList() {
      const { data } = await getCompanyListApi()
      data.map((company) => {
        company.show = false
        company.edit = false
        company.idNo = Decrypt(company.idNo)
      })
      this.companyList = data || []
    },
    showMore() {
      this.show = !this.show
    },
    addCompany() {
      this.$router.push('/add-company-info')
    },
    handleEditCompanyInfo(company) {
      updateCompanyInfoApi(company).then(() => {
        this.$toast({
          type: 'success',
          message: '修改成功',
          duration: 2000,
          onClose: () => {
            company.edit = false
            this.getList()
          },
        })
      })
    },
  },
}
</script>

<style lang="less">
.compony-container {
  background-image: url('../../assets/images/user/bgc.png');
  padding: 2.32rem /* 116/50 */ 0.3rem /* 15/50 */;
  background-size: 100% 3.78rem /* 189/50 */;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  .compony-body {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    width: 100%;
    background-color: #fff;
    margin-top: 0.42rem /* 21/50 */;
    border-radius: 0.08rem /* 4/50 */;
    padding: 0.38rem /* 19/50 */ 0.32rem /* 16/50 */;
    box-sizing: border-box;
    .blue-point {
      display: inline-block;
      width: 0.1rem /* 5/50 */;
      height: 0.1rem /* 5/50 */;
      background-color: #4d7bfe;
      border-radius: 50%;
      margin-right: 0.12rem /* 6/50 */;
    }
    .body-left {
      width: 80%;
    }
    .edit-btn {
      width: 1.4rem;
      height: 0.5rem;
      line-height: 0.5rem;
      text-align: center;
      border: 0.02rem solid #169bd5;
      border-radius: 0.1rem;
      font-size: 0.2rem;
      color: #333;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  input:disabled {
    font-family: PingFangSC-, PingFang SC;
    font-weight: normal;
    opacity: 1;
    -webkit-text-fill-color: #303133 !important;
  }
}
.empty-page {
  width: 100%;
  height: 100vh;
  text-align: center;
  .bg {
    display: block;
    margin: 1rem auto 0.14rem;
  }
  .text {
    font-size: 0.3rem;
    color: #8b8c91;
    margin-bottom: 0.44rem;
  }
  .add-company {
    width: 2.4rem;
    height: 0.84rem;
    line-height: 0.84rem;
    margin: 0 auto;
    background: #f4f7ff;
    border-radius: 4px;
    border: 0.02rem solid #4d7bfe;
    img {
      width: 0.3rem;
      height: 0.3rem;
    }
    span {
      color: #4d7bfe;
      font-size: 0.34rem;
      margin-left: 0.12rem;
    }
  }
}
</style>
